<template>
  <div class="message">{{ message }}</div>
</template>
<script>
import ApiService from "../../services/api.service.js";

export default {
  data() {
    return {
      message: "Loading... Please wait"
    }
  },
  mounted() {
    this.verifyEmail(this.$route.query.token);
  },
  methods: {
    async verifyEmail(token) {
      const requestObj = {
        token: token
      }
      await ApiService.get("user/email-verify/", {params: requestObj})
          .then(() => {
            this.message = "Email successfully activated";
            this.$router.replace("/");
          })
          .catch(() => {
            this.message = "Email activation failed!!";
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  color: red;
  font-family: $font-secondary-bold;
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>